<template>
    <base-index
        title="View Daily Drivers Income"
        :entities="entities"
        :columns="columns"
        @onPageChanged="onPageChanged"
        :config="config">

        <template #controls>
            <base-button
                @click="download"
                size="sm"
                type="neutral"><i class="fa fa-download"></i>Download</base-button>
        </template>

        <template #filters>
            <b-col sm="3" v-if="$store.getters['auth/isSuper']">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Branch <span class="text-danger"></span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getBranches"
                                placeholder="Pick Branch"
                                v-model="filter.branch_id"
                            >
                                <el-option v-for="option in branches"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="option.name"
                                        :key="`${ option.id }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Operation <span class="text-danger"></span></label>
                        <div class="has-label">
                            <el-select
                                filterable
                                clearable
                                @change="getReports"
                                placeholder="Pick Operation"
                                v-model="filter.operation_id"
                            >
                                <el-option v-for="option in operations"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="`${ option.name } (${ option.branch.name })`"
                                        :key="`${ option.id }`">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Driver</label>
                        <div class="has-label">
                            <el-select
                                filterable
                                remote
                                clearable
                                @change="getReports"
                                :remote-method="getDrivers"
                                placeholder="Search Driver By Name Or Phone"
                                v-model="filter.driver_id"
                            >
                                <el-option v-for="option in drivers"
                                        class="select-danger"
                                        :value="option.id"
                                        :label="option.code"
                                        :key="option.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <label class="form-control-label"> Date</label>
                <base-input addon-left-icon="ni ni-calendar-grid-58" class="mt-0">
                    <flat-picker
                            @change="getReports"
                            :config="{allowInput: true}"
                            class="form-control datepicker"
                            v-model="filter.date">
                    </flat-picker>
                </base-input>
            </b-col>
        </template>

        <!-- <template #summary>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">Cash ($)</label>
                        <div class="has-label">
                            <el-input :value="totalPriceUsd" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">Cash (៛)</label>
                        <div class="has-label">
                            <el-input :value="totalPriceRiel" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">ABA ($)</label>
                        <div class="has-label">
                            <el-input :value="totalPriceAbaUsd" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label">ABA (៛)</label>
                        <div class="has-label">
                            <el-input :value="totalPriceAbaRiel" disabled class="cls-form"></el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
        </template> -->

        <template #cell_total_delivery_fee_usd="{ entity }">
            {{(Number(entity.total_delivery_fee_usd) + (Number(entity.total_delivery_fee_riel) / 4000)).toLocaleString() }}
        </template>

    </base-index>
</template>
<script>
import BaseIndex from '@components/Custom/BaseIndex';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
    Select,
    Option,
} from 'element-ui'
import store from './store';
export default {
    data() {
        return  {
            filter: {
                branch_id: '',
                operation_id: '',
                driver_id: '',
                date: new Date(),
                page: 1
            },
            columns: [
                {
                    label: 'Code',
                    key: 'code'
                },
                {
                    label: 'Total Normal',
                    key: 'total_normal'
                },
                {
                    label: 'Total Taxi',
                    key: 'total_taxi'
                },
                {
                    label: 'Total Bus',
                    key: 'total_bus'
                },
                {
                    label: 'Total Delivery ($)',
                    key: 'total_delivery_fee_usd'
                }
            ],
            config: {
                showAddNew: false,
                hasActions: false
            }
        };
    },
    methods: {
        download() {
            store.dispatch('download', this.filter);
        },
        settle(entity) {
            this.popupConfirm('Are you sure?', null, () => {
                store.dispatch('settle', {
                    entity,
                    date: this.filter.date
                })
                .then(() => {
                    this.toastSuccess('Paid Customer');
                    this.getReports();
                });
            });
        },
        getDrivers(search) {
            store.dispatch('getDrivers', {
                search
            });
        },
        getReports() {
            store.dispatch('index', this.filter);
        },
        getBranches() {
            store.dispatch('getBranches')
        },
        onPageChanged(page) {
            this.filter.page = page;
            this.getReports();
        }
    },
    mounted() {
        this.getReports();
        store.dispatch('getOperations');
        this.getBranches();
    },
    computed: {
        entities() {
            return store.getters['entities'];
        },
        operations() {
            return store.getters['operations'].filter(operation => {
                return this.filter.branch_id ? operation.branch_id === this.filter.branch_id : true;
            });
        },
        drivers() {
            return store.getters['drivers'];
        },
        branches() {
            return store.getters['branches'];
        }
    },
    watch: {
        'filter.date': function(val) {
            if (val) {
                this.getReports();
            }
        }
    },
    components: {
        BaseIndex,
        [Select.name]: Select,
        [Option.name]: Option,
        flatPicker
    }
}
</script>